import { isFolder } from '@tenant/utils/helper'

export const useClientDocument = (tenantId = null) => {
    const route = useRoute()
    const router = useRouter()
    const { downloadFile } = useFileStorage()

    const renameItem = ref(null)

    const { entities, entity, setEntity, useApiSearch, useApiDelete } =
        useApiFactory('accountant-documents')
    const { loading: loadingSearch, execute: executeSearch } = useApiSearch(
        {},
        false
    )

    const { loading: loadingDelete, execute: executeDelete } = useApiDelete()

    const {
        execute: executeRename,
        loading: loadingRename,
        errorMessage: errorMessageRename,
    } = useApi('/api/accountant-documents/rename/{id}', 'PATCH')

    const parentFolder = computed(() => entities.value?.folder)

    const headers = computed(() => {
        if (tenantId) {
            return {
                tenant: tenantId,
            }
        }

        return {}
    })

    const refreshSearch = () => {
        return executeSearch(
            {
                ...route.query,
            },
            {
                headers: headers.value,
            }
        )
    }

    const goToFolder = (id) => {
        return router.replace({
            query: {
                id,
            },
        })
    }

    const onDelete = () => {
        return executeDelete(unref(entity).uuid, {
            headers: headers.value,
        }).then(() => {
            setEntity(null)
        })
    }

    const openFolder = (item) => {
        if (!isFolder(item)) {
            return
        }

        return goToFolder(item.uuid)
    }

    const onDownload = async (item) => {
        if (!item.preview) {
            return
        }

        return downloadFile(item.preview.path, item.full_name, {
            headers: headers.value,
        })
    }

    const setRename = (item) => {
        renameItem.value = item
    }

    const onRename = () => {
        if (!renameItem.value) {
            return
        }

        return executeRename({
            params: {
                id: renameItem.value.uuid,
            },
            data: {
                name: renameItem.value.name,
            },
            headers: headers.value,
        }).then(() => {
            renameItem.value = null
        })
    }

    const totalChildFolders = (item) => {
        return item.children.reduce(
            (total, cur) => total + (isFolder(cur) ? 1 : 0),
            0
        )
    }

    const totalChildDocs = (item) => {
        return item.children.reduce(
            (total, cur) => total + (!isFolder(cur) ? 1 : 0),
            0
        )
    }

    return {
        headers,
        renameItem,
        entities,
        entity,
        errorMessageRename,
        parentFolder,
        loadingDelete,
        loadingSearch,
        loadingRename,
        setEntity,
        refreshSearch,
        goToFolder,
        openFolder,
        setRename,
        onDelete,
        onRename,
        onDownload,
        totalChildFolders,
        totalChildDocs,
    }
}
